<template>
  <v-container style="height: 100%">
    <v-row justify="center" align-content="center" style="height: 100%">
      <v-col cols="12" sm="6" md="4">
        <h2 class="font-weight-medium mb-5">Зареєструватися</h2>
        <form class="form-group">
          <v-text-field v-model="firstName" label="Ім'я" required outlined dense></v-text-field>
          <v-text-field v-model="lastName" label="Прізвище" required outlined dense></v-text-field>
          <PhoneEdit
            :value="phone"
            :is-touched="isPhoneTouched"
            label="Телефон"
            validate
            outlined
            dense
            @change="(val) => (phone = val)"
            @validation="(val) => (isPhoneValid = val)"
          />
          <v-text-field
            v-model="password"
            :error-messages="passwordErrors"
            label="Пароль"
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            :error-messages="confirmPasswordErrors"
            label="Підтвердити пароль"
            required
            @input="$v.confirmPassword.$touch()"
            @blur="$v.confirmPassword.$touch()"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            outlined
            dense
          ></v-text-field>
          <v-select
            v-model="country"
            :items="LOCALES"
            item-text="title"
            item-value="locale"
            label="Мова"
            outlined
            dense
            @input="$v.country.$touch()"
            @blur="$v.country.$touch()"
            :error-messages="countryErrors"
          ></v-select>
          <v-checkbox
            v-model="checkbox"
            :error-messages="checkboxErrors"
            label="Погоджуюсь з умовами та надаю згоду на використання моїх особистих даних"
            @change="$v.checkbox.$touch()"
            @blur="$v.checkbox.$touch()"
            dense
          ></v-checkbox>

          <div class="mt-3">
            <v-btn class="mr-4" color="success" @click="submit"> Продовжити </v-btn>
            <v-btn color="error" @click="clear"> Очистити </v-btn>
          </div>

          <div class="login mt-5">
            <router-link :to="`${ROUTER_LINKS.LOGIN}`">Увійти</router-link>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { LOCALES } from '@/const/locales.enum'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import PhoneEdit from '@/components/common/PhoneEdit.vue'

export default {
  name: 'Registration',
  components: { PhoneEdit },
  mixins: [validationMixin],

  validations: {
    firstName: { required },
    lastName: { required },
    password: { required, minLength: minLength(6) },
    confirmPassword: { required, minLength: minLength(6) },
    country: { required },
    checkbox: {
      checked(val) {
        return val
      },
    },
  },

  data: () => ({
    LOCALES: LOCALES,
    ROUTER_LINKS: ROUTER_LINKS,
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    confirmPassword: '',
    country: '',
    checkbox: false,
    showPassword: false,
    showConfirmPassword: false,
    isPhoneValid: false,
    isPhoneTouched: false,
  }),

  computed: {
    hasErr() {
      return this.$v.$anyError || !this.isPhoneValid
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength && errors.push('Не менше 6 символів')
      !this.$v.password.required && errors.push('Це поле обов"язкове')
      return errors
    },
    confirmPasswordErrors() {
      const errors = []
      if (!this.$v.confirmPassword.$dirty) return errors
      !this.$v.confirmPassword.minLength && errors.push('Не менше 6 символів')
      !this.$v.confirmPassword.required && errors.push('Це поле обов"язкове')
      return errors
    },
    countryErrors() {
      const errors = []
      if (!this.$v.country.$dirty) return errors
      !this.$v.country.required && errors.push('Це поле обов"язкове')
      return errors
    },
    checkboxErrors() {
      const errors = []
      if (!this.$v.checkbox.$dirty) return errors
      !this.$v.checkbox.checked && errors.push('Ви повинні погодитися для продовження!')
      return errors
    },
    phoneNumberClear() {
      return this.phone.replace(/[(]|[)]|-/g, '')
    },
  },

  methods: {
    ...mapActions('auth', ['registerUser']),

    async submit() {
      this.$v.$touch()
      this.isPhoneTouched = true
      if (this.hasErr) return null
      const payload = {
        first_name: this.firstName,
        last_name: this.lastName,
        phone: this.phoneNumberClear,
        password: this.password,
        password_confirmation: this.confirmPassword,
        locale: this.country,
      }
      await this.registerUser(payload)
      await this.$router.push({ name: 'Verification', params: { phone: this.phoneNumberClear } })
    },
    clear() {
      this.$v.$reset()
      this.isPhoneTouched = false
      this.firstName = ''
      this.lastName = ''
      this.phone = ''
      this.password = ''
      this.confirmPassword = ''
      this.country = ''
      this.checkbox = false
    },
  },
}
</script>

<style scoped lang="scss"></style>
