export const LOCALES = Object.freeze([
  {
    title: 'Українська',
    locale: 'uk',
  },
  {
    title: 'Русский',
    locale: 'ru',
  },
  {
    title: 'English',
    locale: 'en',
  },
])
